.file_upload_wrapper
{
	@include flex();
	
	align-items: flex-start;
	
	
}

.file_upload_header
{
	color: #1b6378;
	
	margin-bottom: 10px;
}


.download_file_input_wrapper
{
	@include flex();
	
	justify-content: space-between;
	align-items: flex-end;
	
	height: 80px;
	
	margin: 0px 20px;
}