
*
{
	box-sizing: border-box;
	font-family: 'Roboto', Arial, sans-serif;
}

p
{
	margin: 0;
	padding: 0;
}

dd
{
	margin-inline-start: 0px;
}


h1, h2, h3, h4, h5, h6
{
	margin: 0;
}

h1
{
	font-weight: 500;
}

body
{
	@include flex();
	
	justify-content: center;
	
	height: 100vh;
	
	margin: 0;
}

ul
{
	list-style: none;
	margin: 0;
	padding: 0;
}

