.notched_container__wrapper
{
	filter:
		drop-shadow(0px 1px 1px rgba(0, 0, 0, .1))
		drop-shadow(0px 0px 2px rgba(0, 0, 0, .1));
}

.notched_container__content
{
	@include notchCorners(4px, 4px, 12px, 4px);
	@include flex();
	
	
	// margin: 10px 0px;
	
	padding: 20px;
	
	//background: white;
}