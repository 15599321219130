
// Base colors
$dark_green:  #313d42;
$dark_teal:   #1b6378;
$dark_gold:   #d45c2a;
$light_green: #71c6ac;
$light_red:		#ff3333; // Added for handling error features


// Derived colors
$faint_green:      lighten($light_green, 38%);
$lightest_green:   lighten($light_green, 30%);
$lighter_green:    lighten($light_green, 15%);
$dark_light_green: darken($light_green, 30%);
$mid_green:        darken($light_green, 13%);
$mid_dark_green:   darken($light_green, 19%);
$mid_dark_red: 		 darken($light_red, 15%);

$darkish_teal: lighten($dark_teal, 2%);
$mid_teal: lighten($dark_teal, 14%);


$lightish_teal: lighten($dark_teal, 52%);
$light_teal:    lighten($dark_teal, 56%);
$lighter_teal:  lighten($dark_teal, 60%);
$lightest_teal: lighten($dark_teal, 60%);


// Interactive components (ex: buttons, clickable icons)
$usable:   $mid_green;
$hover:    $light_green;
$active:   $lighter_green;
$inactive: $dark_green;

// Backgrounds
$dashboard_bg: $faint_green;
$header_bg:    $dark_teal;
$header2_bg:   mix($dark_teal, #fff, 80%);

//$table_header_bg: mix($dark_teal, #fff, 70%);

$table_header_fg: lighten($dark_teal, 60%);
$table_header_bg: saturate(darken($dark_teal, 4%), 12%);

$toast_bg: saturate(darken($dark_teal, 8%), 15%);


$modal_bg: $lighter_teal;

$pool_bg: lighten($dashboard_bg, 17%);


$form_label: darken($dark_teal, 8%);

$form_input_border: lighten($light_green, 10%);
$form_input_border_hover: darken($light_green, 25%);
$form_input_error: darken($light_red, 12%);

$form_input_bg: lighten($light_green, 40%);
$form_input_bg__autocomplete: mix($form_input_bg, yellow, 86%);


