//=============================================================================
// MUI DIalog / ButtonToOpenDialog Styles
//=============================================================================

// Provides a dropshadow on an invisible element behind the dialog
// (since clip path breaks box shadow)
.dialog_wrapper
{
	//padding: 0 4px 4px 4px;
	filter: 
		drop-shadow(0px 11px 15px rgb(0 0 0 / 20%))
		drop-shadow(0px 24px 38px rgb(0 0 0 / 14%))
		drop-shadow(0px 9px 46px  rgb(0 0 0 / 12%));
}



.create_product_dialog
{
	@include notch4(12px, 4px);
	
	padding: 20px;
	
	width: 75%;
	height: 100%;
	
	max-width: calc(100% - 100px) !important;
}

.create_product_dialog_for_delete
{
	@include notch4(12px, 4px);
	
	padding: 20px;
	
	width: 75%;
	height: 29%;
	min-height: 27px;
	max-width: calc(100% - 100px) !important;
}

.basic_dialog
{
	@include notch4(12px, 4px);
	
	padding: 20px;
	
	//TODO: Properly overwrite MUI PaperProps
	margin: -10px !important;
	overflow-y: hidden !important;
	max-height: 500px !important;
	max-width: 600px !important;
}

.dialog
{
	padding: 20px;
	display: flex;
	justify-content: center;
	// max-width: calc(100% - 100px) !important; // Unsure why I did this in the first place...
}


.large_dialog
{
	// @include notch4(12px, 4px);
	
	padding: 20px;
	
	//TODO: Properly overwrite MUI PaperProps
	margin: -10px !important;
	overflow-y: hidden !important;
	max-height: 75vh !important;
	max-width: 75vw !important;
}


// Use with content that needs to open additional dialogs (ie. a dropdown input)
.base_dialog
{
	// @include notch4(12px, 4px);
	
	padding: 20px;
	
	//TODO: Properly overwrite MUI PaperProps
	margin: -10px !important;
	height: fit-content !important;
	max-height: 90vh;
	max-width: fit-content !important;
	
	// overflow-x: visible !important;
	overflow-y: visible !important;
	
}

.form_dialog
{
	padding: 20px;
	
	height: fit-content !important;
	max-height: 90vh;
	
	min-width: 50vw;
	width: fit-content !important;
	max-width: 80vw;
}

.publish_dialog
{
	padding: 20px;
	
	//TODO: Properly overwrite MUI PaperProps
	margin: -10px !important;
	max-width: 40vw !important;
	width: 40vw !important;
	max-height: 50vh !important;

	// overflow-x: visible !important;
	overflow-y: visible !important;
	
}


#dialog_title
{
	color: #1b6378;
	
	font-size: 1.8em;
	
	padding: 0px 20px 0px 5px;
}


//=============================================================================
// End of Basic Dialog Styling
//=============================================================================

//-----------------------------------------------------------------------------

//=============================================================================
// Custom Dialog Styles
//=============================================================================

// ConfirmationDialog Styling

.confirmation_dialog__detail_header
{
	font-size: 1.3em;
	
	color: #313d42;
	margin: 5px 0px;
	padding-bottom: 5px;
}

.confirmation_dialog__detail_content
{
	padding-left: 5px;
}


.form-content-component,
.form-wrapper-component
{
	background-color: #c6d8dd !important;
}