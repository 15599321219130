//=============================================================================
// Import Sub-Component Styles
//=============================================================================

@import './product_card';



//=============================================================================
// ProductPage Styles
//=============================================================================


.product_page__controls_wrapper
{
	display: flex;
	
	align-items: flex-end;
	justify-content: space-between;
	
	width: 100%;
	
}

.product_page__controls_section
{
	@include flex_row();
	
	align-items: flex-end;
	justify-content: space-between;
	
	width: 100%;
	
}

.product_page__legend_section
{
	@include flex_row();
	
	align-items: center;
	justify-content: space-between;
	
	width: 100%;

}

.product_page__controls_container
{
	align-items: center;
	justify-content: space-between;
	
	width: 100%;
	
	// margin: auto 0px;
	
}

.product_page__controls_container_1
{
	align-items: flex-end;
	justify-content: flex-start;
	
	padding: 0px 10px;
	
	width: 100%;
	
	margin: auto 0px;
	
}

.product_page__controls_container_2
{
	align-items: flex-end;
	justify-content: space-between;
	
	padding: 0px 10px;
	
	width: 100%;
	
	margin: auto 0px;
	
}

.product_page__content
{
	display: flex;
	flex-direction: column;
	
	background: transparent;
}

//=============================================================================
// End of ProductPage Styles
//=============================================================================

//-----------------------------------------------------------------------------

//=============================================================================
// ProductSearch Styles
//=============================================================================

.search_input
{
	align-self: flex-end;
}

//=============================================================================
// End of ProductSearch Styles
//=============================================================================

//-----------------------------------------------------------------------------

//=============================================================================
// ProductFilters Styles
//=============================================================================

.product_page__checkbox_inputs
{
	display: flex;
	flex-direction: column;
	
	margin-top: 20px;
}

.product_page__filter_wrapper,
.product_page__search_wrapper
{
	@include flex_row();
	
	justify-content: space-between;
	align-items: flex-end;	
	
	// TODO: Remove this once we add new filters, also deal with 'search_wrapper'
	width: 45%;
}

.product_page__search_wrapper
{
	width: 55%;
}


//=============================================================================
// End of ProductSearch Styles
//=============================================================================

//-----------------------------------------------------------------------------

//=============================================================================
// ProductCards Styles
//=============================================================================

.product_cards
{
	margin: 20px;
	padding: 10px;
}

.product_cards__header_background
{
	// @include notch4(10px);
	@include notchCorners(0px, 2px, 10px, 2px);
	width: fit-content;
	
	padding: 12px 18px;
	margin-left: -10px;
	margin-top: -10px;
	
	background-color: #1b6378;
}

.product_cards__header
{
	color: #1b6378;
	// font-size: 1.5em;
}

.product_cards__group_of_cards
{
	margin: 0 auto;
	margin-top: 10px;
	margin-bottom: 40px;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(225px , 1fr));
	grid-auto-rows: 1fr;
	grid-column-gap: 4px;
	grid-row-gap: 4px;
	
	background-color: transparent;
	
	
	padding: 8px;
}

//=============================================================================
// End of ProductCards Styles
//=============================================================================

//-----------------------------------------------------------------------------
