

.cardWrapper {
    border: 1px solid #ddd; /* light gray outline */
    padding: 16px;
}

.cardHeader {
    background-color: #f0f8ff; /* light blue background */
    padding: 12px;
}

.cardHeader h2 {
    color: #000; /* black font */
    font-weight: bold;
}

.cardContent {
    background-color: #fff; /* plain white background */
    padding: 16px;
}

.firstRow {
    font-weight: 600; /* semi-bold */
    font-size: 1.2em; /* slightly larger */
}
