// Not in use
.header
{
	display: contents;
}


.header_wrapper
{
	// Set to viewport width - navbar width
	width: calc(100% - 100px);
	
	justify-content: space-between;
	align-items: flex-start;
	
	margin: 30px auto ;
}


.header_shadow
{
	grid-row: 1;
	grid-column: 1 / 3;
	z-index: 20000;
	
	pointer-events: none;
	
	box-shadow: 0 1px 3px 0 #000a;
	border-bottom: 1px solid #000a;
}



// Within right pane
.header_content
{
	grid-area: top_right;
	
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	z-index: 10000;
	
	padding: 8px 16px;
	// background: $header_bg;
	background: transparent;
	color: white;
	
	h1
	{
		@include stroke(#0008);
	}
}






// ========================================
// ========================================

// New Header Content Class
.header_content2
{
	grid-area: right;
	z-index: 9000;
	

	margin: 30px 0px;
	margin-left: 50px;
	
	filter:
		drop-shadow(0px 1px 1px rgba(0, 0, 0, .5))
		drop-shadow(0px 2px 3px rgba(0, 0, 0, .5));
	
	
	h1
	{
		@include stroke(#0008);
	}
}

.page_header
{
	// grid-area: right;
	// z-index: 9000;
	

	// margin: 30px 0px;
	// margin-left: 50px;
	
	filter:
		drop-shadow(0px 1px 1px rgba(0, 0, 0, .5))
		drop-shadow(0px 2px 3px rgba(0, 0, 0, .5));
	
	
	h1
	{
		@include stroke(#0008);
	}
}

.header_container {
	@include flex_row();
	justify-content: space-around;
	
	width: auto;
}

// Creates notched background for Header Tag
.main_header_tag
{
	@include notch4(20px, 6px);
	@include flex_row();
	
	// Renders main tag on top of sub_page_tag
	z-index: 2;

	justify-content: flex-start;

	height: 60px;

	padding: 8px 20px;
	width: fit-content;

	background: #313d42;
	color: #f1faf7;
}

.sub_header_tag
{
	@include notch4(16px, 4px);

	@include flex_row();
	
	justify-content: flex-start;

	height: 40px;
	min-width: 180px;
	width: fit-content;

	// Shift sub_tag down to bottom of main and create overlap
	margin-top: 20px;
	margin-left: -40px;
	
	padding: 8px 20px 8px 60px;

	background: #1B6378;
	color: #f1faf7;
	
}

// .main_header_title
// {
// 	margin-left: 40px;
// }


// ========================================
// ========================================

.account_pic
{
	margin-left: 24px;
	
	box-shadow:
		0 0 2px #000a,
		0 1px 3px 1px #0008;
	
	min-width:  40px;
	height: 40px;
	position: relative;
	overflow: hidden;
	border-radius: 50%;
	user-select: none;
	cursor: pointer;
	
	transition: .15s all;
	
	img
	{
		width:  40px;
		height: 40px;
		//object-fit: cover;
		//object-position: right top;
		//clip-path: circle(50%);
	}
	
	&:hover
	{
		box-shadow:
			0 0 2px #000d,
			0 1px 3px 1px #0008,
			0 0px 3px #0008,
			0 1px 7px #000a;
	}
}


// ========================================
// ========================================


.app_bar
{
	@include flex_row();
	
	width: 100%;
	
	justify-content: space-between;
	
	align-content: flex-start;
}

#notification_button
{
	border-radius: 5px;
	border: 0.5px solid #313d42;
	
	background: #ffffff;
	
	box-shadow:  0px 2px 6px -1px rgba(0,0,0,.12);
	
	min-height: 40px;
	min-width: 40px;
	
	z-index: 100000000000;
	
	display: flex;
	justify-content: center;
	align-items: center;
}

.icon-bell-o 
{
	color: #313d42;
}