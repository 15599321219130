
$notch_size: 30px;
$border_size: 2px;



.products_filter_wrapper
{
	margin: 16px;
}

.product_filters_content
{
	@include notchCorners(
		0,
		0,
		$notch_size - $border_size * .707,
		calc($notch_size / 3 - $border_size * .707)
	);
	
	display: flex;
	justify-content: center;
	align-items: center;
	
	overflow-y: visible;
	
	background: #fff;
	
	padding: 10px;
}

.product_filter_row_wrapper
{
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	overflow-y: visible;
}

.product_filter_column_wrapper
{
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.product_filter_checkbox_wrapper
{
	height: 30px;
	width: 250px;
	
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
}

.product_filter_category_select_wrapper,
.product_filter_input_wrapper
{
	height: 80px;
	width: 250px;
	
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: flex-start;
}

.product_filter_checkbox_wrapper
{
	margin: 10px 0px;
}

.checkbox_background
{
	@include notchCorners(0px, 0px, 8px, 0px);
	
	position: relative;
	
	margin-left: 5px;
	
	width:28px;
	height:28px;
	
	background: #1b6378;
}


// TODO: Style Check Mark
.product_filter_checkbox
{
	@include notchCorners(0px, 0px, 8px, 0px);
	
	position: absolute;
	top: 2px;
	left: 2px;
	
	margin: 0px;
	padding: 2px;
	
	height: 24px;
	width: 24px;
}

.input_background
{
	@include notchCorners(0px, 0px, 8px, 0px);
	
	
	width: 250px;
	min-height: 40px;
	padding: 2px;
	
	background: #1b6378;
}

.product_filter_category_select_dropdown
{
	@include notchCorners(0px, 0px, 8px, 0px);
	
	width: 246px;
	height: 36px;
	// height: auto;
	padding: 5px;
}

