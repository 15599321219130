.material_options_wrapper
{
	@include flex_row();
}

.color_swatch_background
{
	@include notchCorners(1px, 1px, 5px, 1px);
	@include flex();
	
	height: 20px;
	width: 20px;
	background-color: #313d42;
	margin: 3px;
	margin-right: 10px;
}

.color_swatch
{
	margin: 1px;


	@include notchCorners(1px, 1px, 4px, 1px);
}