
@mixin button(
	$background_color:   $usable,
	$border_color:       $mid_dark_green,
	$border_color_hover: $dark_light_green,
)
{
	@include usable();


	border: 1px solid $border_color;

	background: $background_color;
	
	
	box-shadow:
		inset 0 0 1px $border_color_hover,
		0 0px 2px 0 #000c,
		0 1px 3px 0 #000a;


	&:not([disabled])
	{
		&:hover
		{
			border: 1px solid $border_color_hover;

			
			box-shadow:
				inset 0 0 1px $border_color_hover,
				inset 0 0 2px $border_color_hover,
				0px 0px 3px $border_color_hover,
				0 0px 2px 0 #000c,
				0 1px 4px 0 #0007,
				0 2px 4px 0 #0006,
				0 2px 5px 1px #0003;
		}
		
		&:focus,
		&:active
		{
			border: 1px solid $border_color_hover;
			//border-radius: 5px;
			outline: none;
			box-shadow:
				inset 0 0 1px $border_color_hover,
				inset 0 0 2px $border_color_hover,
				0px 0px 6px $border_color_hover,
				0 0px 2px 0 #000c,
				0 1px 4px 0 #0007,
				0 2px 4px 0 #0006,
				0 2px 5px 1px #0003;
		}
	}
}



// =============================================================================



@mixin disabled_button()
{
	$border_color: black;

	border: 1px solid #0003 !important;

	background: #888;
	
	box-shadow:
		inset 0 0 1px #000c,
		0 0px 2px 0 #000c,
		0 1px 3px 0 #000a;
}



// =============================================================================
//primary button - main action on a screen or in a particular section of a user interface
.button
{
	@include button();
	
	display: flex;
	align-items: center;
	
	padding: 6px;

	font-size: 12px;
	font-weight: 500;
	letter-spacing: 1.3px;
	
	
	//=====================================================
	// updated styling from Upside
	@include notchCorners( 2px, 2px, 12px, 2px);
	
	filter:
		drop-shadow(0px 1px 1px rgba(0, 0, 0, .1))
		drop-shadow(0px 0px 2px rgba(0, 0, 0, .1));
	
    cursor: pointer;
    color: #f1faf7; 
    // background: #313d42;
    background: #46af8f;
	
    border: none; 
	outline: 0;
	
    box-shadow: none; 
    display: flex;
	justify-content: center;
    align-items: center;
	
	min-width: 100px;
	height: 40px;
	
	&:not([disabled])
	{
		&:hover
		{
			box-shadow: none; 
			border: none;
		}
	}
	
	&:focus
	{
		border: none;
		outline: 0;
	}

	.icon
	{
		margin-right: 5px;
	}
	
	&.next_button
	{
		justify-self: flex-end;
		margin-left: auto;
	}

	&:disabled
	{
		@include disabled_button();
	}
}

// A button for less important actions that are typically alternatives or complementary to the primary action
.secondary_button
{
	background: transparent;
	
	color: #1b6378;
	
	text-decoration: none;
	
	&:hover
	{
		background: transparent;
		text-decoration: underline;
		color: rgba(27, 99, 120, 0.5)
	}
}

.cancel_button
{
	@include button(firebrick, darken(firebrick, 5%), darken(firebrick, 18%));
	
	
	&:hover
	{
		background: lighten(firebrick, 9%);
	}
	&:active
	{
		background: saturate(lighten(firebrick, 18%), 20%);
	}
}

.icon_button
{
	@include notchCorners( 0px, 0px, 0px, 0px);
	
	background: #71c6ac;
	
	color: #f1faf7;
	
	width: fit-content;
	
	min-width: 32px;
	
	border-radius: 120px;
	
	padding: 8px;
	
	&:hover
	{
		background: lighten(#71c6ac, 18%);
		// color: darken(#313d42, 10%)
	}
}

//For status chip - hover effect
.interactable_status_chip :hover{
	color: #313d42;
	cursor: pointer;
	transition: 1s ease-in-out;
}

// A Copy button is typically a button designed to copy specific content
.copy_button
{
	background: #d1ac06;
	
	color: #ffffff;
	
	text-decoration: none;
	
	&:hover
	{
		background: #f5db38;
		box-shadow: none; 
		border: none;
	}
}