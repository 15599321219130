//=============================================================================
// Renders a section within the Product Detail w/ header and content section
//=============================================================================

.product_detail__detail_section,
.product_detail__detail_secondary
{
	color: black;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	height: fit-content;
	width: 100%;
	padding: 10px 0px;
	white-space: pre-wrap;
}

.product_detail__detail_secondary
{
	margin: 10px;
}

.product_detail__description,
.product_detail__description_secondary
{
	margin-top: 5px;
	padding: 10px;
	text-align: left;
}

.product_detail__description_secondary
{
	padding-top: 0px;
	margin-top: 0px;
	padding-left: 5px;
	
	max-width: 300px;
}

.product_detail__description_header
{
	width: 100%;
	height: 15px;
	padding-bottom: 3px;
	margin-bottom: 25px;
	align-items: center;
}

.product_detail__header_content
{
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

}

.product_detail__description_header_text
{
	width: fit-content;
	color: #1b6378;
}

.product_detail__header_line
{
	width: 100%;
	border-bottom: 2px solid #1b6378;
}

.detail_spacer
{
	margin-top: 20px;
}