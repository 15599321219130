
.legend_container
{
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-end;
	align-self: flex-end;
	margin-right: 32px;
	
	// width: 100%;
	
	// margin: 20px;
	// margin: -120px 0px 40px 0px;
	// margin-top: -100px;
	// margin-left: auto;
	// margin-bottom: 14px;
}

.legend_wrapper
{
	@include notchCorners(4px, 4px, 12px, 4px);
	@include flex_row();
	
	justify-content: space-between;
	align-items: flex-start;
	align-self: flex-end;
	
	padding-right: 12px;
	
	background: white;
}

.product_legend__wrapper
{
	
	@include notchCorners(4px, 4px, 12px, 4px);
	@include flex_row();
	
	justify-content: space-between;
	align-items: center;
	align-self: center;
	
	min-width: 300px;
	
	padding-right: 12px;	
}

.legend__legend_item
{
	@include flex_row();
	align-items: center;
	justify-content: center;
	
	max-width: '40px';
	
	margin: 10px;
}

.product_legend__legend_item
{
	margin: 10px 30px;
}

.product_legend__item_title
{
	h4
	{
		white-space: nowrap;
	}
}

.legend__swatch_wrapper
{
	filter:
		drop-shadow(0px 1px 1px rgba(0, 0, 0, .3))
		drop-shadow(0px 0px 1px rgba(0, 0, 0, .6));
}

.legend_item_swatch
{
	@include notchCorners(2px, 2px, 6px, 2px);
	
	width: 24px;
	height: 24px;
	
	margin-right: 12px;
}

.legend_item_title_approved::after
{
	content: " *";
	color: #1b6378;
}

.legend_item_title_approved_meaning
{
	color: #1b6378;
	margin-left: 5px;
	margin-right: 20px;
	padding-bottom: 10px;
}