

.icon
{
	line-height: 1;
	vertical-align: middle;
}


.action_icon
{
	@include usable(fg);
	
	align-self: center;
	
	background: rgba($dark_teal, .15);
	
	padding: 5px;
	border-radius: 34px;
	
	font-size: 24px;
	height: 34px;
	
	cursor: pointer;
	box-shadow:
		0 0   2px #0007,
		0 1px 2px #0003;
	
	&:hover
	{
		background: rgba($dark_teal, .08);
		box-shadow:
			0 0   3px #0007,
			0 1px 3px #0003;
	}
}

