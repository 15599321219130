.scroll-down {
	position: absolute;
	left: 42%;
    bottom: 100px;
    display: block;
    text-align: center;
    font-size: 20px;
    text-decoration: none;
    text-shadow: 0;
    width: 13px;
    height: 13px;
    border-bottom: 2px solid black;
    border-right: 2px solid black;
    transform: rotate(45deg);
    animation: fade-move-down 3s cubic-bezier(0.19, 1, 0.22, 1) infinite;
  
	// Add media query for responsiveness if needed
	@media (max-width: 768px) {
	  bottom: 70px;
	  width: 10px;
	  height: 10px;
	  border-bottom: 1.5px solid black;
	  border-right: 1.5px solid black;
	}
  }
  
  @keyframes fade-move-down {
	0% {
	  opacity: 0;
	  transform: translate(-50%, 0) rotate(45deg);
	}
	50% {
	  opacity: 1;
	  transform: translate(-50%, 20px) rotate(45deg);
	}
	100% {
	  opacity: 0;
	  transform: translate(-50%, 40px) rotate(45deg);
	}
  }
  