.multi_select_dropdown
{ 
	@include notchCorners(0px, 0px, 10px, 0px);
	
	width: 246px;
	padding: 3px;
	
	border: none;
	
	background: #1b6378;
	
}

.multi_select__control
{
	@include notchCorners(0px, 0px, 8px, 0px);
	border-radius: 0px;
	border-style: none;
	border-width: 0px;
	background-color: #fff;
	
}

.multi_select__control--is-focused,
.multi_select__control--menu-is-open
{
	
	border-radius: 0px;
	border-style: none;
	border-width: 0px;
	background-color: #fff;
}
