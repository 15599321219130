//=============================================================================
// ProductCard Styles
//=============================================================================


.product_card__wrapper
{
	cursor: pointer;
	
	transition: $timing_normal all;
	
	width: 260px;
	height: 210px;
	
	
	filter:
		drop-shadow(0px 1px 1px rgba(0, 0, 0, .6))
		drop-shadow(0px 0px 2px rgba(0, 0, 0, .6));
	
	&:hover
	{
		filter:
			drop-shadow(0px 1px 1px rgba(0, 0, 0, .8))
			drop-shadow(0px 0px 5px rgba(0, 0, 0, .7));
		
		.product_card2
		{
			background: $lighter_green;
		}
	}
}


.product_card
{
	@include notch4(14px, 3px);
	
	
	width: '100%';
	// max-width: 225px;
	
	margin: 8px;
	padding: 4px;
	
	background: $light_green;
	
	border-radius: 2px;
	
	user-select: none;
	
	transition: $timing_normal all;
	
	
	.image
	{
		justify-content: center;
		transition: $timing_normal all;
		
		position: absolute;
		top: 3;
		
		// green = 162, teal = 194 => 32deg rotation
		// Note that CSS hue rotations are not accurate
		filter: hue-rotate(0deg) saturate(70%) brightness(105%);
		//filter: hue-rotate(0deg) saturate(25%) brightness(105%);
	}
	
	&:hover
	{
		.image
		{
			filter: hue-rotate(0deg) saturate(120%) brightness(85%);
				//hue-rotate(32deg) saturate(100%) brightness(95%);
		}
	}
}


.product_card__content_wrapper
{
	filter:
		drop-shadow(0px 1px 1px rgba(0, 0, 0, .2))
		drop-shadow(0px 0px 1px rgba(0, 0, 0, .4));
}


.product_card__content
{
	@include notch4(4px, 4px);
	
	position: relative;
	
	display: flex;
	flex-direction: column;
	justify-content: center;
	//align-items: center;
	
	min-height: 170px;
	
	padding: 4px;
	
	background: #fff;
}


.product_card__content_category
{
	color: #0005;
	// margin-left: 6px;
	// margin-right: 20px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	
	margin: auto;
}

.product_card__content_overlay
{
	@include notchCorners(0px, 0px, 5px, 0px);
	
	position: absolute;
	
	top: 0;
	left: 0;
	
	//width: 40px;
	// max-width: 60px; //added this for auto resizing 
	
	padding: 2px 5px;
	
	background: #b1aeae;
	
	box-shadow: 0 0px 1px 2px #fff4;
	
	font-weight: 600;
	font-size: 14px;
}

.product_card__content_overlay_cost
{
	@include notchCorners(5px, 0px, 0px, 0px);
	
	position: absolute;
	
	bottom: 0;
	right: 0;
	
	//width: 40px;
	// max-width: 60px; //added this for auto resizing 
	
	padding: 2px 5px;
	
	background: #b1aeae;
	
	box-shadow: 0 0px 1px 2px #fff4;
	
	font-weight: 600;
	font-size: 14px;
}

.product_card__content__overlay
{
	position: absolute;
	bottom: 0;
	right: 0;
	
	margin: 2px;
	padding: 2px;
}

.product_card__heading
{
	padding: 4px 8px;
	
	h4
	{
		font-weight: 500;
		
		text-align: left;
		
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}