.ideas_admin_page,
.admin_page,
.settings_page
{
	background: #fff;
	
	height: fit-content;
	
	margin: 0px 3rem 2rem 3rem !important; //overwrite MUI margin
	
	padding: 1rem;
	
	border-radius: 10px;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	
	// TODO: Specifity boost hack
	&.ideas_admin_page,
	&.admin_page,
	&.settings_page
	{
		width: unset;
	}
}

.invite_users_admin_page
{
	margin-top: 40px;
	display: flex;
	
	height: 90%;
	
	justify-content: space-between;
}


//ADD USERS SECTION
.add_users_section
{
	margin-right: 30px;
	
	height: 100%;
	width: 65%;
	padding: 10px;
	overflow: auto;
}

.add_users_section_heading
{
	font-weight: 500;
	font-size: 16px;
	
	color: rgb(36, 35, 35);
	
	padding-bottom: 5px;
	border-bottom: 1px solid rgb(195, 195, 195);
	
	margin-bottom: 10px;
}


//Styling for New Items Info
.new_item_info_container
{
	border-radius: 5px;
	
	padding: 10px;
	margin-top: 25px;
	margin-bottom: 25px;
	
	max-width: 70%;
	
	box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
				rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}


.new_item_section_header
{
	display: flex;
	
	align-items: center;
	justify-content: space-between;
	
	margin-bottom: 15px;
}


.invite_form_item_number
{
	color: rgb(44, 43, 43);
	
	padding: 3px 8px;
	margin-right: 10px;
	
	border-radius: 4px;
	border: 2px solid rgb(216, 216, 216);
	
	font-weight: 500;
	font-size: 16px;
}

.invite_form_item_info_title
{
	font-size: 16px;
	font-weight: 500;
	
	color: rgb(42, 41, 41);
}

.invite_form_other_fields
{
	display: flex;
	
	justify-content: space-between;
	flex-wrap: wrap;
}


.section_details_title
{
	font-size: 14px;
	
	
	background: rgb(144, 144, 144);
	color: #fff;
	
	width: fit-content;
	padding: 2px 4px;
	border-radius: 2px;
	margin-bottom: 10px;
}

.section_detail_containers
{
	margin-top: 4px;
	margin-bottom: 25px;
}





// CONFIRM INVITES SECTION
.confirm_invites_section
{
	width: 35%;
	height: fit-content;
	min-height: 80%;
	
	border-radius: 10px;
	box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, 
				rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
	
	padding: 10px;
}



.confirm_invites_header
{
	font-weight: 500;
	font-size: 16px;
	
	color: rgb(36, 35, 35);
	
	padding-bottom: 5px;
	border-bottom: 1px solid rgb(195, 195, 195);
	
	margin-bottom: 10px;
}

.confirm_invites_end_section
{
	margin-top: 60px;
	
	display: flex;
	flex-direction: column;
	
	align-items: flex-end;
}


.invitation_summary_section
{
	margin-bottom: 70px;
}

.total_invitations
{
	padding: 5px;
	margin-top: 15px;
	
	background: rgb(61, 61, 61);
	color: #fff;
	
	font-weight: 500;
	font-size: 16px;
	border-radius: 5px;
}







// Invitation Ready User Card
.invitation_ready_card_container
{
	margin-top: 20px;
	display: flex;
	
	justify-content: space-between;
	
	border-radius: 5px;
	
	background-color: #E9E9E9;
}

.invitation_ready_user_info
{
	display: flex;
	flex-direction: column;
	
	width: 70%;
	
	
	align-items: flex-start;
	justify-content: center;
	padding: 10px;
}

.invitation_to_section
{
	display: flex;
}

.invitation_user_name
{
	font-weight: 500;
	font-size: 13px;
	
	margin-bottom: 5px;
}

.invitation_organization_name
{
	font-weight: 500;
	font-size: 13px;
	
	margin-bottom: 5px;
	margin-left: 5px;
}

.invitation_email_address
{
	font-weight: 500;
	font-size: 13px;
	
	color: #3a3a3a;
}


.invitation_department_info
{
	display: flex;
	flex-direction: column;
	
	
	align-items: center;
	justify-content: space-around;
	background: #F2F2F2;
	
	padding: 10px;
	
	width: 30%;
	height: inherit;
	margin: 0;
	
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
}

.invitation_department_code_heading
{
	font-weight: 500;
	font-size: 13px;
	
	color: #3a3a3a;
}

.invitation_department_code
{
	font-weight: 500;
	font-size: 15px;
}

.user_invitation_alert
{
	z-index: 10000000;
	
	position: absolute;
	
	bottom: 50%;
	right: 50%;
}