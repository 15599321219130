//=============================================================================
// Import Sub-Component Styles
//=============================================================================

.order_detail__heading_wrapper
{
	@include flex_row();
	justify-content: space-between;
	
	color: #1b6378;
}


//=============================================================================
// OrderDetail Styles
//=============================================================================



//=============================================================================
// End of OrderDetail Styles
//=============================================================================

//-----------------------------------------------------------------------------

//=============================================================================
// Sub-Component Styles
//=============================================================================

.status_chip_wrapper
{
	@include flex_row();
	
	width: 100%;
	
	justify-content: center;
}