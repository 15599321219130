.color_picker
{
	display: flex;
	flex-direction: column;
	align-items: center;

	.swatch
	{
		display: flex;
		flex-direction: row;
		align-items: center;
	}
}