


.product_list_item
{
	display: flex;
	flex-direction: row;
}


.admin_grid
{
	background-color: white;
}


.admin_controls
{
	margin-bottom: 24px;
}


.create_product_button
{
	//margin-bottom: 24px;
	align-self: center;
}





.upload_form
{
	display: flex;
	flex-direction: column;
	
	justify-content: center;
	align-items: center;
}


.upload_form__files
{
	padding: 8px;
}


.upload_form__file
{
	display: flex;
	align-items: center;
}


.upload_form__file__extension
{
	display: flex;
	align-items: center;
	
	color: red;
	
	font-size: 12px;
	
	margin-right: 16px;
	
}


// .upload_form__file__name
// {
// 	padding: 8px;
// }



// Adding specificity to beat MUI
body .upload_form__submit
{
	@include usable();
}


.file_icon_with_type
{
	position: relative;
	
	text-align: center;
	
	svg
	{
		font-size: 64px;
	}
	
	p
	{
		@include absolute();
		
		padding-top: 8px;
		line-height: 64px;
		font-size: 14px;
		
		display: inline-block;
		text-align: center;
		vertical-align: middle;
		
		color: white;
	}
}



.admin_user_table_spinner
{
	position: absolute;
	top: 40%;
	left: 50%;
	// height: fit-content;
}