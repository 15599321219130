//=============================================================================
// Import Component Styles into top-level style sheet
//=============================================================================


//=============================================================================
// SearchInput Styles
//=============================================================================

.search_input__wrapper
{
	@include flex();
	justify-content: space-evenly;
	align-items: flex-start;
}

.product_search__search_results
{
	width: 100%;
}

//=============================================================================
// End SearchInput Styles
//=============================================================================

//---------------------------------------------------------------------------- 

//=============================================================================
// CheckboxInput Styles
//=============================================================================

.checkbox_input__checkbox_wrapper
{
	@include flex_row;

	justify-content: space-evenly;

	label
	{
		cursor: pointer;
		user-select: none;
	}
}


.checkbox_input__checkbox_label
{
	display: flex;
	align-items: center;

	font-size: 16px;
	
	font-weight: normal;
	
	padding: 5px;
	
	cursor: pointer;

	span
	{
		padding-left: 10px;
	}
}

.checkbox_input__background
{
	@include notchCorners(0px, 0px, 8px, 0px);

	position: relative;

	width:24px;
	height:24px;
	
	cursor: pointer;

	background: #2e7d32;
}

// TODO: Style Check Mark
.checkbox_input__checkbox
{
	@include notchCorners(0px, 0px, 8px, 0px);

	position: relative;
	
	background-color: #2e7d32;

	margin: 0px;
	margin-right: 5px;
	padding: 2px;

	height: 20px;
	width: 20px;
}

//=============================================================================
// End CheckboxInput Styles
//=============================================================================

//---------------------------------------------------------------------------- 

//=============================================================================
// SelectInput Styles
//=============================================================================

// Override MUI's pop-over component base z-index to work in our UI
.MuiModal-root
{
	z-index: 21000 !important;
}


//=============================================================================
// End SelectInput Styles
//=============================================================================

//---------------------------------------------------------------------------- 

//=============================================================================
// ReactHookFormNumber Input Styles
//=============================================================================

.product_detail__order_quantity
{
	// TODO: too hacky, pls fix with actual code at some point
	width: 110px !important;
}




//=============================================================================
// End ReactHookFormNumber Input Styles
//=============================================================================

//---------------------------------------------------------------------------- 

//=============================================================================
// ReactHookFormSelect Input Styles
//=============================================================================

.material_options_wrapper
{
	@include flex_row();
}

.color_swatch_background
{
	@include notchCorners(0px, 0px, 5px, 0px);
	position: relative;
	height: 20px;
	width: 20px;
	background-color: black;
	margin: 3px;
	margin-right: 10px;
}

.color_swatch
{
	position: absolute;
	top: 1px;
	bottom: 1px;
	left: 1px;
	right: 1px;


	@include notchCorners(0px, 0px, 4px, 0px);
}