// Login page styling
$primary: #1B6378;
$secondary: #71C6AC;

$form_bg: #fefefe;


//------------------------------ClassName not used in broadside-------------------------------------//
.login-form
{
	display:  flex;
	flex-direction: row;
	align-items:  center;
	justify-content:  flex-start;
	
	height:  100vh;
	width:  100vw;
	margin:  0 0;
	
	background: $primary;
	
	&:before
	{
		@include absolute();
		
		z-index: 1;
		background: $form_bg;
		clip-path: polygon(0 0, 52% 0, 78% 100%, 0% 100%);
		//box-shadow:  0px 2px 6px -1px rgba(0,0,0,.12);
	}
}


//------------------------------ClassNames not used in broadside-------------------------------------//
.login-page h4
{
	font-size:  24px;
	font-weight:  600;
	color:  #000;
	opacity:  .85;
}


.login-page label
{
	font-size:  12.5px;
	color:  #000;
	opacity:  .8;
	font-weight:  400;
}


.login-page form
{
	padding:  40px 30px;
	background: #fefefe;
	display:  flex;
	flex-direction:  column;
	align-items:  flex-start;
	padding-bottom:  20px;
	width:  500px;
	
	h2
	{
		margin-bottom:  20px;
		font-size: 30px;
		color:  rgba(#000, .5);
		
		span
		{
			color:  rgba(#000, 1);
			font-size: 28px;
			font-weight: 600;
		}
	}
	
	p
	{
		line-height:  155%;
		margin-bottom:  5px;
		font-size:  22px;
		color:  #000;
		opacity:  .65;
		font-weight:  500;
		max-width:  400px;
		margin-bottom:  40px;
	}
}

.login-page a.discrete
{
	color:  rgba(#000, .4);
	font-size:  16px;
	border-bottom:  solid 1px rgba(#000, .0);
	padding-bottom:  4px;
	margin-left:  auto;
	font-weight:  300;
	transition:  all .3s ease;
	margin-top:  40px;
	
	&:hover
	{
		border-bottom:  solid 1px rgba(#000, .2);
	}
}




$foo: 16px;



.login-page button
{
	@include notch4($foo);
	
	
	width:  auto;
	min-width:  100px;
	text-align:  center;
	padding:  15px 40px;
	margin-top:  5px;
	background-color: $secondary;
	color:  #fff;
	font-size:  16px;
	margin-left:  auto;
	font-weight:  500;
	box-shadow:  0px 2px 6px -1px rgba(0,0,0,.13); 
	border:  none;
	transition:  all $timing_quick ease; 
	outline: 0;
	
	&:hover
	{
		transform:  translateY(-3px);
		box-shadow:  0 2px 6px -1px rgba($primary, .65);
		
		&:active
		{
			transform:  scale(.99);
		}
	}
	
	//clip-path: polygon(25% 0%, 100% 0, 100% 45%, 75% 100%, 0 100%, 0 0);
}




.login-page input
{
	font-size:  16px;
	padding:  20px 0px;
	height:  56px;
	border:  none;
	border-bottom:  solid 1px rgba(0,0,0,.1);
	background:  #fff;
	width:  280px;
	box-sizing:  border-box;
	transition:  all .3s linear;
	color:  #000;
	font-weight:  400;
	
	&:focus {
		border-bottom:  solid 1px $primary; 
		outline: 0; 
		box-shadow:  0 2px 6px -8px rgba($primary, .45);
	}
}


.session
{
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	
	width: 80%;
	height: inherit;
	background:  #ffffff;
	box-shadow:  0px 2px 6px -1px rgba(0,0,0,.12);
	
	clip-path: polygon(0 0, 52% 0, 78% 100%, 0% 100%);
}




.floating-labe
 {
	position:  relative;
	margin-bottom:  10px;
	width:  100%;
	
	label
	{
		position:  absolute;
		top: calc(50% - 7px);
		left:  0;
		opacity:  0;
		transition:  all .3s ease;
		padding-left:  44px;
	}
	
	input
	{
		width:  calc(100% - 44px); 
		margin-left:  auto;
		display:  flex;
		font-weight: 400;
	}
	
	.login-page-icon
	{
		position:  absolute;
		top:  0;
		left:  0;
		height:  56px;
		width:  44px;
		display:  flex;
		
		svg
		{
			height:  30px;
			width:  30px;
			margin:  auto;
			opacity:  .15;
			transition:  all .3s ease;
			
			path
			{
				transition:  all .3s ease; 
			}
		}
	}
	
	.eye-icon
	{
		position: absolute;
		top: 0;
		right: 0;
		height:  56px; 
		width:  44px; 
		display:  flex;
		
		svg
		{
			height:  30px; 
			width:  30px; 
			margin:  auto;
			opacity:  .15; 
		}
		&:hover
		{
			svg
			{
				opacity: 0.60;
				color: $primary;
			}
			transform: translateY(-2px);
			
			&:active
			{
				svg
				{
					opacity: 1;
					color: $primary;
				}
			}
		}
	}
	
	
	
	
	input:not(:placeholder-shown)
	{
		padding:  28px 0px 12px 0px; 
	}
	
	input:not(:placeholder-shown) + label
	{
		transform:  translateY(-10px); 
		opacity:  .7; 
	}
	
	input:valid:not(:placeholder-shown) + label + .login-page-icon
	{
		svg {
			opacity: 1;
			path {
				fill: $primary; 
			}
		}
	}
	
	input:not(:valid):not(:focus) + label + .login-page-icon
	{
		animation-name: shake-shake;
		animation-duration: .3s;
	}
}



$displacement:  3px;


@keyframes shake-shake
{
	0% { transform: translateX(-$displacement);}
	20% { transform: translateX($displacement); }
	40% { transform: translateX(-$displacement);}
	60% { transform: translateX($displacement);}  
	80% { transform: translateX(-$displacement);}
	100% { transform: translateX(0px);}
}


.signup-error
{
	color: $light_red;
	margin-left: auto;
	font-size: small;
}
//----------------------------------------------------------------------//

//--------------------Classnames used ----------------------------------//

.registration_email_field
{
	width: 90%;
	
	background: lighten(rgba(63,94,251,1), 35%);
}

.registration_page_user_message
{
	margin-bottom: 15px;
	font-size: 18px;
	font-weight: 500;
	
	color: rgb(42, 42, 42);
}



.login_error
{
	color: $light_red;
	margin-left: auto;
	font-size: medium;
	margin-bottom: 10px;
}


.login_error::before
{
	content: "\26A0 ";
	margin-right: 5px;
}

.reset_email_message
{
	color: $light_red;
	margin-left: auto;
	font-size: medium;
	margin-bottom: 10px;	
}


.pdf_container {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 10px 0;
	padding: 10px;
	height: 100%;
	width: 100%;
}
