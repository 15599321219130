

$notch_size: 30px;
$border_size: 2px;



.tabloid_wrapper
{
	filter:
		drop-shadow(0px 1px 3px rgba(0, 0, 0, .3))
		drop-shadow(0px 0px 1px rgba(0, 0, 0, .6));
}


.tabloid
{
	@include notch4($notch_size, calc(#{$notch_size} / 3));
	
	//padding: 0 4px 4px 4px;
	
	background: $dark_teal;
	
	border: 3px solid $dark_teal;
}


.newtabloid
{
	
	background-color: transparent;
	
}



.tabloid__header
{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	
	padding: 8px 16px;
	
	color: white;
	background: $dark_teal;
	
	h2
	{
		font-size: 26px;
		font-weight: 400;
	}
}

.tabloid__header_clear
{
	background-color: 'transparent';
	color: #313d42;
	
	
	// font-size: 30px;
	// font-weight: bolder;
	// padding-top: 40px;
	
	h2
	{
		//margin-top: 20px;
		margin-bottom: 15px;
		
		font-size: 26px;
		font-weight: 600;
	}
}

.tabloid__header_details
{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
}

.tabloid__detail_stretch
{
	width: 100%;
	display: flex;
	//justify-content: space-between;
}


.tabloid__content
{
	@include notchCorners(
		0,
		0,
		calc(#{$notch_size} - #{$border_size} * .707),
		calc(#{$notch_size} / 3 - #{$border_size} * .707)
	);
	
	background: #eee;
}

