





.upload_avatar
{
	display: flex;
	align-items: center;
	justify-content: center;
	
	padding: 8px;
	
	transition: .2s ease;
	
	
	
	label
	{
		position: relative;
		
		cursor: pointer;
		
		
		border-radius: 50%;
		border: 1px solid white;
		
		
		.avatar_overlay
		{
			@include absolute();
			
			overflow: hidden;
			border-radius: 50%;
			
			border: 2px solid black;
			
			opacity: 0;
			background: #333;
			
			transition: .2s ease;
		}
		
		
		.avatar_overlay_icon
		{
			@include absolute();
			
			top: 50%;
			left: 50%;
			bottom: unset;
			
			transform: translate(-50%, -50%);
			text-align: center;
			
			opacity: 0;
			color: white;
			
			transition: .2s ease;
			
			svg
			{
				font-size: 76px;
			}
		}
		
		
		&:hover
		{
			.avatar_overlay
			{
				opacity: .6;
			}
			.avatar_overlay_icon
			{
				opacity: 1;
			}
		}
	}
}





.file_groups
{
	padding: 12px;
}

.file_group
{
	margin:  8px;
	padding: 8px;
	background: #0001;
	border-radius: 5px;
	
	box-shadow: 0 1px 2px 1px #0003;
	transition: $timing_normal all;
	
	&:hover
	{
		box-shadow: 0 2px 4px 1px #0006;
		transition: .2s all;
	}
}




.image_gallery
{
	display: flex;
	flex-direction: row;
	justify-content: center;
	flex-wrap: wrap;
}

.preview
{
	max-width:  364px;
	max-height: 182px;
	
	margin: 4px;
	
	border-radius: 5px;
	box-shadow: 0 1px 2px 1px #0006;
	
	transition: $timing_normal all;
	
	&:hover
	{
		box-shadow: 0 2px 4px 1px #0009;
		transition: .2s all;
	}
}

.summary
{
	transition: .7s all;
}





.file_upload_box
{
	position: relative;
	
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	
	overflow: hidden;
	
	min-height: 150px;
	min-width: 200px;
	
	background: #0001;
	border-radius: 5px;
	
	box-shadow: 0 0px 0 1px #0001,
	            0 1px 2px -1px black;
	
	transition: $timing_normal all;
	
	.icon
	{
		font-size: 64px;
	}
	
	&.can_drop
	{
		transition: .2s all;
		background: lighten($dark_teal, 25%);
		box-shadow: 0 0px 0 1px #0002,
		            0 1px 3px -1px black,
		            0 1px 2px 1px #0004;
	}
	
	&:hover,
	&.active
	{
		transition: .2s all;
		background: lighten($dark_teal, 30%);
		box-shadow: 0 1px 2px 1px $dark_teal,
		            0 1px 3px -1px black,
		            0 1px 3px 1px #0006;
	}
}


.file_upload_text
{
	padding: 12px;
}


.file_upload_input
{
	position: absolute;
	
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	
	width: 100%;
	
	opacity: 0;
	
	cursor: pointer;
}


.file_list
{
	display: flex;
	flex-direction: column;
	
	padding: 8px;
}








.folder_tree
{
	background: #ddd;
	
	padding-right: 5px;
	
	border-radius: 5px;
	
	& > .folder_tree__folder
	{
		margin-left: 0;
	}
}


.folder_tree__folder
{
	display: flex;
	flex-direction: column;
	
	margin-left: 12px;
	padding: 3px 0 3px 3px;
}

.folder_tree__folder_details
{
	font-weight: 500;
}

.folder_tree__file_details
{
	padding-left: 8px;
	padding-right: 4px;
	
	font-weight: 300;
}

.folder_tree__file_details,
.folder_tree__folder_details
{
	display: flex;
	align-items: center;
	
	cursor: pointer;
	user-select: none;
	
	margin-left: 5px;
	
	border-radius: 5px;
	
	p
	{
		vertical-align: middle;
	}
	
	&:hover
	{
		background: #0001;
	}
}

.folder_tree__folder_children
{
	margin-left: 8px;
}

.folder_tree__file
{
	padding-left: 8px;
}

.folder_tree__folder_details__text,
.folder_tree__file_details__text
{
	display: flex;
	flex-direction: row;
	flex-grow: 1;
	justify-content: space-between;
	
	margin: 1px;
	margin-left: 7px;
	
	padding: 2px;
}













.file_list_item
{
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	
	margin: 4px;
	padding: 4px;
	
	background: #0001;
	border-radius: 7px;
	box-shadow: 0 1px 2px 1px #0003;
	transition: .35s all;
	
	
	&:hover
	{
		background: #0000000A;
		box-shadow: 0 1px 2px 1px #0006;
		transition: .25s all;
	}
	
	
	.name
	{
		font-size: 16px;
		padding: 6px;
		line-height: 1;
	}
	
	.details
	{
		display: flex;
		flex-direction: column ;
		align-items: center;
		
		padding: 4px;
	}
	
	.preview
	{
		width: 200px;
		height: 200px;
		
		border-radius: 5px;
		box-shadow: 0 1px 2px 1px #0006;
	}
	
	
	.size
	{
		position: relative;
		
		margin: 4px;
		min-width: 76px;
		
		&::after
		{
			position: absolute;
			content: '';
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			border-radius: 5px;
			box-shadow: inset 0 0 2px 1px #0009, 0 1px 3px 0px #0009;
		}
	}
	
	.size_bar
	{
		position: relative;
		text-align: center;
		background: #000C;
		z-index: -2;
		border-radius: 5px;
		overflow: hidden;
	}
	
	.size_bar_fill
	{
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		content: '';
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
		z-index: -1;
		overflow: hidden;
		
		width: attr(data-width px);
		
		background: $dark_teal;
	}
	
	.size_bar_text
	{
		color: #FFF;
		padding: 4px 6px;
		z-index: 2;
		font-size: 11px;
		
	}
}

















