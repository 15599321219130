//=============================================================================
// Import Sub-Component Styles
//=============================================================================

@import 'detail_section';


//=============================================================================
// ProductDetail Styles
//=============================================================================

.product_detail__content_window
{
	margin: 10px;
}


.order_form__row_wrapper
{
	@include flex_row();
	
	justify-content: space-between;
	
	margin: 20px 0px;
}

.order_form__submit_wrapper
{
	margin-top: 40px;
	justify-content: flex-end;
}


//=============================================================================
// End of ProductDetail Styles
//=============================================================================

//-----------------------------------------------------------------------------

//=============================================================================
// Sub-component Styles
//=============================================================================

.product_detail__images
{
	@include notchCorners(0px, 8px, 0px, 8px);
	
	width: 100%;
	height: 100%;
	position: relative;
	background-color: #1b6378;
	
	
	max-width: 300px;
	max-height: 300px;
	
	min-height: 250px;
	min-width: 250px;
	
}

.product_detail__images_showcase
{
	@include notchCorners(0px, 7px, 0px, 7px);
	@include flex();
	
	position: absolute;
	inset: 3px;
	
	background-color: white;
}

.product_detail__showcase_image
{
	background: white;
}