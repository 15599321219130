//=============================================================================
// OrderCard Styles
//=============================================================================

.order_card_wrapper{
	
	@include flex();
	
	transition: $timing_normal all;
	
	filter:
		drop-shadow(0px 1px 1px rgba(0, 0, 0, .6))
		drop-shadow(0px 0px 2px rgba(0, 0, 0, .6));
	
	&:hover
	{
		filter:
			drop-shadow(0px 1px 1px rgba(0, 0, 0, .8))
			drop-shadow(0px 0px 5px rgba(0, 0, 0, .7));
		
		.product_card2
		{
			background: $lighter_green;
		}
	}
	
	cursor: pointer;
	
	margin: 10px;
	
}

.order_card
{
	@include notch4(24px, 3px);
	
	width: 100%;
	
	min-height: 120px;
	
	margin: 8px;
	padding: 4px;
	
	background: #1b6378;
	
	border-radius: 2px;
	
	user-select: none;
	
	transition: $timing_normal all;
}

.order_card_content_wrapper,
.order_card_content_wrapper_new
{
	@include notch4(22px, 3px);
	
	position: relative;
	
	padding: 20px;
	
	background: #fff;
	
	min-height: 120px;
	// min-width: 250px;
	width: 100%;
}

.order_card_content_wrapper_new
{
	@include flex_row();
	justify-content: space-between;
	align-items: center;
}

.order_card_details_wrapper
{
	@include flex_row();
	justify-content: space-between;
}

.order_card_product_wrapper,
.order_card_product_wrapper_new
{
	@include flex_row();
	justify-content: space-evenly;
	
	padding: 10px 0px;
}

.order_card_product_wrapper_new
{
	width: 100%;
	height: 100%;
	
	align-items: center;
}

.order_card_product_image
{
	// margin-right: 20px;
	position: relative;
}

.order_card__quantity_chip
{
	position: absolute;
	bottom: 8px;
	right: -10px;
	
	background-color: #c6d8dd;
}

.order_card_product_description
{
	@include flex();
	align-items: flex-start;
}

.order_item_product_title
{
	max-width: 250px;
}

.order_card_product_wrapper
{
	@include flex();
}

.order_card_detail_list
{
	min-width: 200px;
}


.order_card_accordion_summary
{
	position: relative;
}

