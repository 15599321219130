
.dashboard
{
	@include crossHatch();
	
	display: flex;
	width: 100vw;
	height: 100vh;
}

.avatar
{
	padding-right: 15px;
}

.avatar:hover
{
	opacity: 0.5;
	cursor: pointer;
}

.logo
{
	@include usable(fg);
	
	grid-area: top_left;
	z-index: 10000;
	
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	
	background: $header_bg;
}

.main_logo
{
	@include usable(fg);
	
	grid-area: top_left;
	z-index: 10000;
	
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	padding-top: 5px;
	background: #313d42;
}




// Main area of screen, within right pane, below header
main
{
	flex-grow: 1;
	display: flex;
	align-items: stretch;
	flex-direction: column;
	justify-content: flex-start;
	overflow-y: auto;
	overflow-x: hidden;
	
	height: 100%;
	
	article
	{
		
		margin-top: 40px;
		padding: 0px 6%;
	}
}

.login-page
{
	height:  100vh; 
	width:  100vw;
	margin:  0 0;
	
	display:  flex;
	flex-direction: row;
	align-items:  center;
	justify-content:  flex-start;
	
	background: $primary;
}

// Loader styling
.loader
{
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid rgb(59, 182, 166);
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

@keyframes spin
{
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}