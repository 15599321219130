// TODO: Calcs not working as expected, clip-path is still applied so long as all
// 		 args are properly supplied to the mixin when it is called


// Specify each corner notch size clockwise starting at top left
@mixin notchCorners($nw, $ne, $se, $sw)
{
	// Prevent calc from being dumb and breaking on calc(100% - 0)
	@if $nw == 0
	{
		$nw: 0px;
	}
	@if $ne == 0
	{
		$ne: 0px;
	}
	@if $se == 0
	{
		$se: 0px;
	}
	@if $sw == 0
	{
		$sw: 0px;
	}
	
	
	clip-path:
		polygon(
			0% $nw,                   /* top left */
			$nw 0%,                   /* top left */
			calc(100% - #{$ne}) 0%,   /* top right */
			100% $ne,                 /* top right */
			100% calc(100% - #{$se}), /* bottom right */
			calc(100% - #{$se}) 100%, /* bottom right */
			$sw 100%,                 /* bottom left */
			0% calc(100% - #{$sw})    /* bottom left */
		);
}


// Notch bottom right
@mixin notch1($size)
{
	@include notchCorners(0, 0, $size, 0);
}


// Notch bottom right + bottom left
@mixin notch2($size1, $size2: null)
{
	@if $size2 == null
	{
		$size2: calc(#{$size1} / 3);
	}
	
	@include notchCorners(0, 0, $size1, $size2);
}


// Notch bottom right + other 3 corners
@mixin notch4($size1, $size2: null)
{
	@if $size2 == null
	{
		$size2: calc(#{$size1} / 3);
	}
	
	@include notchCorners($size2, $size2, $size1, $size2);
}


// Notches all 4 corners
// Takes 1, 2, or 3 arguments specifying sizes - (SE), (SE, SW), (SE, SW, NW/NE)
@mixin notch($big_notch_size, $little_notch_size: null, $top_notch_size: null)
{
	@if $little_notch_size == null
	{
		$little_notch_size: calc(#{$big_notch_size} / 3);
	}
	
	@if $top_notch_size == null
	{
		$top_notch_size: calc(#{$big_notch_size} / 3);
	}
	
	@include notchCorners($little_notch_size, $little_notch_size, $big_notch_size, $little_notch_size);
}









// Use for buttons, clickable icons, etc. to apply a standard theme
// Specify fg or bg to determine which styling to use (fg for icons, bg for buttons)
@mixin usable($fg_or_bg: bg)
{
	transition: $timing_normal all;
	cursor: pointer;
	text-decoration: none;
	user-select: none;
	
	@if $fg_or_bg == fg
	{
		color: $usable;
		
		&:hover
		{
			color: $hover;
		}
		&:active
		{
			color: $active;
		}
	}
	@else
	{
		color: #eee;
		background: $usable;
		
		&:hover
		{
			color: #fff;
			background: $hover;
		}
		&:active
		{
			color: #fff;
			background: $active;
		}
	}
}








@mixin flex()
{
	display: flex;
	flex-direction: column;
	align-items: center;
}

@mixin flex_row()
{
	display: flex;
	flex-direction: row;
	align-items: center;
}

@mixin card()
{
	border-radius: 5px;
	
	cursor: pointer;
	
	background: #FFFB;
	
	&:hover
	{
		background: #FFFF;
	}
}

@mixin shadow_w_hover()
{
	box-shadow: 0 1px 2px 0px #0005;
	transition: .35s all;
	
	&:hover
	{
		cursor: pointer;
		box-shadow: 0 2px 4px 1px #0003, 0 1px 2px 0px #0003;
		transition: .25s all;
	}
}








.stroke_wrapper
{
	display: flex;
	flex-wrap: wrap;
	// justify-content: center;
	justify-content: flex-start;
	
	.stroke
	{
		padding: 0 .15em;
	}
	
	.whitespace
	{
		white-space: nowrap;
		width: .3em;
	}
}


//------------------------------------------------------------------------------
// This applies a stroke to text. This requires a mixin because the standard CSS
// definition of outline is actually a midline - the stroking marker is centered
// on the edge of the characters, which means that the "outline" goes inside the
// letterform and corrupts its shape.
// 
// This adds a lower-z-level 'before' pseudoelement that is stroked and
// shadowed. The actual text then covers up the inner outline bullshit, thereby
// preserving the original character's edges.
// 
// NOTE: The text element must have its text in a 'data-text' attribute so the
//       pseudoelement knows what to use as its content.
//------------------------------------------------------------------------------
@mixin stroke($color: black)
{
	position: relative;
	
	z-index: 1;
	
	
	&::before
	{
		position: absolute;
		
		z-index: -1;
		
		content: attr(data-text);
		
		-webkit-text-stroke: 2px $color;
		
		text-shadow:
			
			// diagonals
			 1px  1px 1px rgba($color, .7 * alpha($color)),
			 1px -1px 1px rgba($color, .7 * alpha($color)),
			-1px -1px 1px rgba($color, .7 * alpha($color)),
			-1px  1px 1px rgba($color, .7 * alpha($color)),
			
			// orthagonals
			 0px  1px 1px rgba($color, .7 * alpha($color)),
			 1px  0px 1px rgba($color, .7 * alpha($color)),
			 0px -1px 1px rgba($color, .7 * alpha($color)),
			-1px  0px 1px rgba($color, .7 * alpha($color)),
			
			// omnidirectional and downward
			0 0px 2px $color,
			0 1px 2px rgba($color, .5 * alpha($color)),
			0 2px 1px rgba($color, .1 * alpha($color))
		;
	}
}

// EFFECTS
.stroke
{
	@include stroke();
	color: white;
}

.light_stroke
{
	@include stroke(#0002);
	color: white;
}

.stroke_on_hover:hover
{
	@include stroke();
}

*:hover > .stroke_on_parent_hover
{
	@include stroke();
}






// This is most commonly used for a before/after pseudoelement to ensure
// that it occupies the same space as the element. This is useful for shadows,
// stroking text, etc.
@mixin absolute()
{
	position: absolute;
	
	content: '';
	
	top:    0;
	right:  0;
	bottom: 0;
	left:   0;
}





// Makes grid, rotated at 45 degrees
@mixin crossHatch(
	$bg_color: $faint_green,
	$stripe_color: $lightest_green,
	$pattern_width: 30px,
	$stripe_width: 1px,
	$stripe_opaque_ratio: .6,
)
{
	$bg_color_a: rgba($bg_color, 0);
	
	
	// Beginning of gradient
	$size1: calc(#{$stripe_width} / 2);
	// Beginning of solid line
	$size2: calc(#{$stripe_opaque_ratio} * #{$stripe_width} / 2);
	// Position of center of stripe
	$stripe_pos: calc(#{$pattern_width} - #{$size1});
	
	
	&::before
	{
		@include absolute();
		
		z-index: -1000;
		
		background: repeating-linear-gradient(
			45deg,
			$bg_color,
			$bg_color calc(#{$stripe_pos} - #{$size1}),
			$stripe_color calc(#{$stripe_pos} - #{$size2}),
			$stripe_color calc(#{$stripe_pos} + #{$size2}),
			$bg_color calc(#{$stripe_pos} + #{$size1}),
			$bg_color $pattern_width
		);
	}
	
	&::after
	{
		@include absolute();
		
		z-index: -1000;
		
		background: repeating-linear-gradient(
			-45deg,
			$bg_color_a,
			$bg_color_a calc(#{$stripe_pos} - #{$size1}),
			$stripe_color calc(#{$stripe_pos} - #{$size2}),
			$stripe_color calc(#{$stripe_pos} + #{$size2}),
			$bg_color_a calc(#{$stripe_pos} + #{$size1}),
			$bg_color_a $pattern_width
		);
	}
}