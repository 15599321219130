.bar_component
{
	margin-top: 50px;
	align-items: center;
	
	
	h2
	{
		margin-left: 125px;
	}
	
}

.bar_charts
{
	display: flex;
	flex-direction: row;
	align-items: center;
	
	box-shadow:
	0 0   1px #0005,
	0 1px 3px #0003;
	
}
.line_charts
{
	display: flex;
	flex-direction: row;
	align-items: center;
	
	box-shadow:
	0 0   1px #0005,
	0 1px 3px #0003;
	
}