// Variables
@import 'variables/colors';
@import 'variables/sizes';
@import 'variables/timings';

// Functions
@import 'functions/fade';

// Mixins
@import 'mixins/mixins';

// Reset and Base
@import 'base/reset';
@import 'base/base';




// Components
@import 'components/icon';
@import 'components/buttons';
@import 'components/color_picker';
@import 'components/graphs';
@import 'components/pairs';
@import 'components/files';
@import 'components/product_filters';
@import 'components/multi_select_dropdown';
@import 'components/_tabloid';
@import 'components/_inputs';
@import 'components/_notched_container';
@import 'components/_legend';
@import 'components/color_swatch';
@import 'components/modal';
@import 'components/dialog';
@import 'components/header';


//Login page
@import 'login_page';

// Pages (and sub-components)
@import 'pages/products_page/products_page';
@import 'pages/product_detail/product_detail';
@import 'pages/ideas_page';

@import 'pages/orders_page/orders_page';
@import 'pages/order_detail/order_detail';


// App
@import 'dashboard';
//Support page

@import 'pages/support_page';
// Page
@import './pages/settings_page';
@import './pages/admin';


@import 'pdf_components';
@import 'components/scroll_down_animation';



.orders
{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	
	
	background: blue;
	
	padding: 12px 20px;
	margin: 8px;
}


.order
{
	display: flex;
	flex-direction: column;
	
	
	background: cornflowerblue;
	
	padding: 12px 20px;
	margin: 8px;
	
	.image
	{
		//width: 100px;
		//height: 100px;
	}
}




// TODO: Move
.image
{
	display: flex;
	align-items: center;
	justify-items: center;
	align-self: center;
	justify-self: center;
	
	//object-fit: scale-down;
	
	//margin: auto;
	//padding: 8px;
	
	//background: white;
	
	// border-radius: 7px;
	
	
	img
	{
		display: block;
		
		width: auto;
		height: auto;
	
		max-width: 100%;
		max-height: 100%;
		
		object-fit: scale-down;
		
		// margin: 0 auto;
		padding: 0;
		margin: 0;
	}
}


